import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ProductListing } from "../../components/product-listing"
import { Seo } from "../../components/seo"
import { RenderMDX } from "../../components/render-mdx"
// import { FunkyHr } from "../../components/funky-hr"
// import { formatPrice } from "../../utils/format-price"
// import { Hero } from "../../components/hero"
// import { StaticImage } from "gatsby-plugin-image"

export default function ProductTypeIndex(props) {
  const {
    data: { products, allMdx },
    pageContext,
  } = props
  const { i18n, lang } = pageContext
  const oneInchSlacklines = products.nodes
    .filter((p) => p.tags.includes("25mm"))
    .sort((a, b) => {
      // Check if compareAtPrice is present in any variants of product a
      const aHasCompareAtPrice = a.variants.some(
        (variant) => variant.compareAtPrice !== null
      )

      // Check if compareAtPrice is present in any variants of product b
      const bHasCompareAtPrice = b.variants.some(
        (variant) => variant.compareAtPrice !== null
      )

      // Prioritize products with compareAtPrice
      if (aHasCompareAtPrice && !bHasCompareAtPrice) {
        return -1
      } else if (!aHasCompareAtPrice && bHasCompareAtPrice) {
        return 1
      }

      return 0 // No change in order if both have or don't have compareAtPrice
    })

  const twoInchSlacklines = products.nodes.filter((p) =>
    p.tags.includes("50mm")
  )

  const h2Classes = "mb-4 text-4xl font-bold text-center"

  return (
    <div className="out-wrapper">
      <Seo lang={lang} title={i18n.products.title} />
      {/* <Hero title={i18n.products.title}>
        <StaticImage
          className="!absolute z-0 w-full h-full"
          src="../../assets/images/banner_feet.jpg"
          alt=""
        />
      </Hero> */}
      {/* <h1 className="my-16 font-bold text-center">{i18n.products.title}</h1> */}
      <section className="mt-16">
        <h2 className={h2Classes}>{i18n.products.oneInchTitle}</h2>
        <p>{i18n.products.oneInchSubTitle}</p>
        <ProductListing
          classes="justify-center mt-3"
          products={oneInchSlacklines}
          i18n={i18n}
        />
      </section>
      <section className="my-10">
        <h2 className={h2Classes}>{i18n.products.twoInchTitle}</h2>
        {RenderMDX(allMdx.nodes[0].body)}
        <ProductListing
          classes="justify-center"
          products={twoInchSlacklines}
          i18n={i18n}
        />
      </section>
      <section className="max-w-3xl mx-auto my-10 text-center">
        <h2 className={h2Classes}>{i18n.products.courseTitle}</h2>
        <a href="https://slacklinemastery.com">
          <div>
            <StaticImage
              src={`../../assets/images/slackline_mastery.jpg`}
              alt=""
            />
          </div>

          <div className="flex flex-col items-center font-bold">
            <h3 className="mt-2 text-2xl transition duration-300 text-neutral-500 group-hover:shadow-linkUnderline">
            The Slackline Mastery Course Bundle
            </h3>
            <div className="text-sm text-neutral-400">
              {i18n.products.courseDescription}
            </div>
          </div>
        </a>
        <p className={`mt-3 text-lg`}>{i18n.products.courseSubTitle}</p>
      </section>
      {/* <FunkyHr /> */}

      {/* 
      Kit Gymnase
      <section className="my-10">
        <h2 className="mb-6 text-3xl font-bold">
          {i18n.products.selfSuportingTitle}
        </h2>
        <div className="flex flex-col items-center max-w-sm pb-2 m-2">
          <StaticImage
            className="max-w-[20rem]"
            src="../../assets/images/kit_ecole.jpg"
            alt={i18n.products.gymnasiumKitAlt}
          />
          <h3 className="mt-6 text-2xl font-bold text-neutral-500">
            {i18n.products.gymnasiumKit}
          </h3>
          <p>
            <a className="link-underline" href={i18n.slugs.contact}>
              {i18n.products.specialOrder1}
            </a>{" "}
            {i18n.products.specialOrder2}
          </p>
          <p className="text-lg font-bold">{formatPrice("CAD", 539)}</p>
          <a className="link-underline" href="https://youtu.be/vS5cIZV9f4Q">
            {i18n.common.installationVideo}
          </a>
        </div>
      </section> */}

      {/* <ProductListing products={products.nodes} i18n={i18n} /> */}

      {/* {products.pageInfo.hasNextPage && (
        <MoreButton to={`/search?p=${slugify(productType)}#more`}>
          More Products
        </MoreButton>
      )} */}
    </div>
  )
}

export const query = graphql`
  query ProductsQuery($lang: String) {
    products: allShopifyProduct(
      # sort: { fields: publishedAt, order: ASC }
      filter: { tags: { in: ["25mm", "50mm"] } }
      limit: 24
    ) {
      nodes {
        ...ProductCard
        tags
      }
      pageInfo {
        hasNextPage
      }
    }
    allMdx(
      filter: { frontmatter: { id: { eq: "desc2" }, lang: { eq: $lang } } }
    ) {
      nodes {
        body
      }
    }
  }
`
